import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

const Kids = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { target, studentID } = parsedLocation;

  useRedirectHandler(() => {
    if (target === "loginKid") {
      return `https://home.classdojo.com/#/student-selector?loginKid=true${studentID ? `&studentID=${studentID}` : ""}`;
    } else {
      return "https://home.classdojo.com/#/student-selector";
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Kids;
